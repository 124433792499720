import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { ChatAuthModel } from './ChatAuthModel'

export const chatAuth: ChatAuthModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.chat.auth,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    ChatAuthModel.ResponseData,
    void,
    ChatAuthModel.RequestData,
    ChatAuthModel.RequestHeaders,
    ChatAuthModel.ErrorResponse
  >({
    url: path,
    data: null,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}
