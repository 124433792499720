import React, { useEffect, useRef } from 'react'
import { DefaultPage } from '@/layouts/DefaultPage'
import styled from '@emotion/styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Rules } from '@/models/Rules'
import { chatAuth } from '@/api/chat/auth'
import { ResponseType } from '@/models/ResponseType'

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}))

const chatWidgetCommandOpen = function () {
  window.chatWidget.open()
}
const chatWidgetCommandClose = function () {
  window.chatWidget.close()
}

const Page = ({ url = '/chat' }) => {
  const ref = useRef()
  const context = useAppContext<AppContext>()
  const isAuth = context.auth.rules.has(Rules.Access)

  function documentReadyForChatWidgetInit() {
    window.chatWidgetState = 0
    window.chatWidget.onOpened(function () {
      window.chatWidgetState = 1
    })
    window.chatWidget.onMinimized(function () {
      window.chatWidgetState = 2
    })
    window.chatWidget.onClosed(function () {
      window.chatWidgetState = 0
    })
    window.chatWidget.onDuplicateLock(function () {
      window.chatWidget.close()
    })
    window.chatWidget.onDuplicateUnlock(function () {
      if (window.chatWidget.getState() === 0) {
        window.chatWidgetState = 0
      }
    })

    window.chatWidget.setAuthTokenFunc(async function (callbackAction) {
      const response = await chatAuth({ apiVersion: context.config.apiVersion })

      if (response?.data?.type === ResponseType.ResolveWithData) {
        callbackAction({
          JwtToken: response.data.jwtToken,
        })
      }
    })

    //оборачивание SecureKey в токен - secure-key v1 (артефакт, поэтому имитируем)
    window.chatWidget.setWrapSecureKeyFunc(function (callbackAction, key) {
      callbackAction(key)
    })

    //извлечение SecureKey из токена - secure-key v1 (артефакт, поэтому имитируем)
    window.chatWidget.setGetSecureKeyFunc(function (callbackAction, key) {
      callbackAction(key)
    })
  }

  const deleteItems = () => {
    localStorage.removeItem(
      'chat-widget-storage-0.1.1.1/chat-prolongation-token-request-notifications-data'
    )
    localStorage.removeItem('chat-widget-storage-0.1.1.1/chat-instance-live')
    localStorage.removeItem('chat-widget-storage-0.1.1.1/chat-session-auth-done-data')
  }

  useEffect(() => {
    import(url + '.js').then((a) => {
      if (ref.current) {
        window.rend(ref.current)
        documentReadyForChatWidgetInit()
        setTimeout(() => {
          chatWidgetCommandOpen()
        }, 100)
      }
    })
    // todo: fix it start
    // временное решение для нивелирования багов виджета
    window.addEventListener('beforeunload', () => {
      chatWidgetCommandClose()
      deleteItems()
    })
    return () => {
      removeEventListener('beforeunload', deleteItems)
      chatWidgetCommandClose()
      deleteItems()
      window.location.reload()
    }
    // to do fix end
  }, [])

  return (
    <DefaultPage hideMobileHeader hideFooter isExternal={!isAuth} isChat>
      <style>{`@import "/chat.css"`}</style>
      {<Wrapper ref={ref} id="chat-widget" />}
    </DefaultPage>
  )
}

export default Page
